/** @format */

.footer {
  padding: 20px;
  background-color: var(--primary-color);
  color: var(--light-text);
}

.original-footer {
  padding: 40px 0;
  text-align: center;
  font-family: Arial, sans-serif;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: gray;
}

ul {
  list-style: none;
}

li {
  margin-bottom: 10px;
}

.link {
  color: white;
  font-size: 1rem;
  text-decoration: none;
}

.link:hover {
  color: white !important;
}

.logo-footer {
  width: 40% !important;
  object-fit: cover !important;
}

@media (max-width: 768px) {
  .footer-main,
  .logo-footer,
  .columns-footer {
    display: none !important; /* Hide the existing footer elements on mobile */
  }

  .mobile-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem !important;
  }

  .mobile-logo-footer {
    width: 15% !important;
    align-self: self-start;
  }

  .mobile-footer-columns {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .mobile-column {
    flex: 1;
    justify-content: center;
    text-align-last: end;
  }

  .original-footer {
    padding: 0 !important;
  }
}
