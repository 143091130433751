/** @format */
@import url("https://fonts.googleapis.com/css2?family=Nevan&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "react-multi-carousel/lib/styles.css";

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif, "Nevan";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  scroll-behavior: smooth;
}

/* Define colors based on the site */

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font-family: "Manrope", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;
  --primary-color: #18a0fb;
  --secondary-color: #f7f9fa;
  --text-color: #4a4a4a;
  --light-text: #ffffff;
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

@font-face {
  font-family: "Nevan";
  src: local("Nevan"), url("./../src/assets/fonts/Nevan.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Neue";
  src: local("Neue"), url("./../src/assets/fonts/Neue.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "AauxMedium";
  src: local("AauxMedium"),
    url("./../src/assets/fonts/AauxMedium.otf") format("opentype");
  font-weight: medium;
}

@font-face {
  font-family: "AauxBold";
  src: local("AauxBold"),
    url("./../src/assets/fonts/AauxBold.otf") format("opentype");
  font-weight: bold;
}
