/** @format */

.about-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack elements vertically */
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.about-title {
  font-size: 4.6rem;
  color: black;
}

.about-title-wrapper {
  position: relative;
  color: #4d4545;
}

.underline {
  width: 77px;
  height: 6px;
  background: linear-gradient(to right, #2787c8, #65bfb1, #2787c8);
  position: absolute;
  left: 7%;
  bottom: 2px;
  transform: translateX(-50%);
}

.info-container {
  display: flex;
  justify-content: space-between;
}

.info-text-wrapper {
  display: flex; /* This will keep them side by side on desktop */
  justify-content: space-between;
}

.info-text {
  width: calc(50% - 2.5rem); /* Subtract half of the space between the divs */
  font-family: "AauxMedium";
}

.info-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin: auto;
}

.gradient-button {
  height: 30px;
  width: 200px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 50px;
  background: linear-gradient(90deg, #65bfb1 0%, #65bfb1 50%, #2787c8 100%);
  color: white !important;
  margin-top: 1rem !important;
}

.gradient-button:hover {
  background: linear-gradient(90deg, #2787c8 0%, #65bfb1 50%, #65bfb1 100%);
}

.lower-section {
  position: relative;
  height: 25rem !important;
  background: #91909c;
  margin-top: 5rem;
}

.lower-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("../../assets/aboutUsBackground.jpeg") no-repeat center center;
  background-size: cover;
}

.lower-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(27, 23, 51, 0.8);
}

.lower-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3 !important;
}

.lower-section-title {
  color: white !important;
  margin-top: 2rem !important;
}

.lower-section-subtitle {
  color: white !important;
  margin-top: 0.5rem !important;
}

.lower-section-images {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin-top: 5rem;
}

.lower-section-icon-container {
  height: 9.2rem;
  width: 9.2rem;
  border: 2px solid white;
  padding: 2rem;
  box-sizing: border-box;
}

.lower-section-icon {
  height: 5.2rem;
  width: 5.2rem;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .info-container .info-text {
    width: 100% !important;
    padding: 1rem !important;
  }

  .info-text-wrapper {
    flex-direction: column; /* This will stack them vertically on mobile */
    margin-top: -5rem !important;
  }

  .info-text {
    width: 100% !important;
    padding: 1rem !important;
    margin-bottom: 1rem; /* You can adjust this for spacing */
  }

  .info-button-container .gradient-button {
    border-radius: 5px !important;
    margin-left: 1rem !important;
  }

  .about-us-container {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .lower-section {
    margin-top: 2rem !important;
    height: auto !important;
  }

  .lower-section::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(27, 23, 51, 0.5);
  }

  .lower-section-images {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem;
    margin-top: 0rem !important;
  }

  .lower-section-icon-container {
    display: flex !important;
    flex-basis: calc(50% - 1.5rem);
    margin-bottom: 2rem;
    height: auto !important;
    width: 2rem !important;
    display: flex; /* Adds Flexbox */
    align-items: center; /* Centers the icon vertically */
    justify-content: center; /* Centers the icon horizontally */
    border: 4px solid white !important;
  }

  .lower-section-icon {
    max-width: 100%;
    height: 2.5rem !important;
    padding: 0 !important;
  }
}
