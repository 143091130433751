/** @format */

.about-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack elements vertically */
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.about-title {
  font-size: 4.6rem;
  color: black;
}

.about-title-wrapper {
  position: relative;
  display: inline-block;
  color: #4d4545;
}

.underline-skill {
  width: 77px;
  height: 6px;
  background: linear-gradient(to right, #2787c8, #65bfb1, #2787c8);
  position: absolute;
  left: 12%;
  bottom: 2px;
  transform: translateX(-50%);
}

.info-container-skills {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2rem;
  width: 90%;
  margin: 0 auto;
}

.info-text-skills {
  padding: 1rem;
  display: flex;
  flex-direction: row;
}

.info-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin: auto;
}

.gradient-button {
  height: 30px;
  width: 200px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 50px !important;
  background: linear-gradient(90deg, #65bfb1 0%, #65bfb1 50%, #2787c8 100%);
  color: white !important;
  margin-top: 1rem !important;
}

.gradient-button:hover {
  background: linear-gradient(90deg, #2787c8 0%, #65bfb1 50%, #65bfb1 100%);
}

.lower-section {
  height: 25rem;
  background: #91909c;
  margin-top: 5rem;
}

.lower-section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lower-section-title {
  color: white !important;
  margin-top: 2rem !important;
}

.lower-section-subtitle {
  color: white !important;
  margin-top: 0.5rem !important;
}

.lower-section-images {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin-top: 5rem;
}

.lower-section-icon-container {
  display: inline-block;
  height: 9.2rem;
  width: 9.2rem;
  border: 2px solid white;
  padding: 2rem;
  box-sizing: border-box;
}

.lower-section-icon {
  height: 5.2rem;
  width: 5.2rem;
  object-fit: contain;
}

.lower-section-skills {
  position: relative;
  height: 32rem;
  background: linear-gradient(to right, #f5f5f5 40%, #f5f5f5 100%);
  margin-top: 5rem;
}

.lower-section-skills::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40%;
  background: url("../../assets/skillsPeople.jpeg") no-repeat center center;
  background-size: cover;
}

.lower-section-skills::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40%;
  background: rgba(
    27,
    23,
    51,
    0.7
  ); /* This represents green color with 20% opacity */
  z-index: 1;
}

.lower-section-content-skills {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 10%;
}

.about-title-skills {
  margin-top: 1rem !important;
  font-size: 4.6rem;
  color: white;
}

.about-title-wrapper {
  position: relative;
  display: inline-block;
  color: #4d4545;
  z-index: 2;
}

.underline-skills {
  width: 77px;
  height: 6px;
  background: linear-gradient(to right, #2787c8, #65bfb1, #2787c8);
  position: absolute;
  left: 8%;
  bottom: 2px;
  transform: translateX(-50%);
}

.skills-cards-container {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  width: 100%;
  position: relative;
  z-index: 2;
  margin-left: 10%;
}

.skill-card .card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.skill-card {
  width: 25%;
  height: 25rem;
  background: white;
  text-align: -webkit-center;
}

.card-icon {
  width: 6.5rem;
  height: 3.7rem;
  object-fit: cover;
}

.card-content {
  overflow: auto;
}

.info-image-skills {
  width: 6.5rem;
  height: 6.5rem;
  margin-top: 2rem;
}

.info-title-skills {
  margin-bottom: 1rem !important;
  font-weight: bold;
  font-family: "AauxBold" !important;
}

.info-main-text-skills {
  font-family: "AauxMedium" !important;
}

.info-text-container {
  padding: 1rem;
}

/* Styles for screens smaller than 800px */
@media (max-width: 800px) {
  .skill-card {
    width: 50%; /* cards will be wider on smaller screens */
  }

  .info-container-skills {
    display: flex !important;
    flex-direction: column !important;
    grid-template-columns: 1fr; /* Use 1 column layout for mobile */
    grid-template-rows: repeat(4, 1fr); /* 4 rows to accommodate all items */
  }

  .info-text-skills {
    flex-direction: row; /* Stack items horizontally on mobile */
    padding: 0 !important;
    display: flex; /* Ensures that the parent is a flex container */
    align-items: start; /* This will vertically center all children of .info-text-skills */
  }

  .info-image-skills {
    width: 3rem;
    height: 3rem;
    margin-top: 1rem !important;
  }

  .info-title-skills {
    margin-bottom: 0rem !important;
    font-weight: bold !important;
    font-family: "AauxBold" !important;
    font-size: 22px !important;
    padding: 0 !important;
    color: #439cd6 !important;
  }

  .info-button-container {
    width: 95% !important;
  }

  .card-icon {
    width: 3.5rem !important;
    height: 3.7rem !important;
    object-fit: cover;
  }
  .info-text-container {
    display: flex;
    flex-direction: column;
    align-self: center !important;
    padding: 1rem !important;
    margin-top: -0.5rem;
  }

  .skills-cards-container {
    display: flex;
    flex-direction: column;
    gap: 0 !important;
    width: auto !important;
  }

  .skill-card {
    height: auto !important;
    border-radius: 0 !important;
    background-color: rgba(232, 233, 234, 0.8) !important;
  }

  .about-title-wrapper {
    align-self: center !important;
    margin-bottom: 5rem;
  }

  .lower-section-skills {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(rgba(27, 23, 51, 0.5), rgba(27, 23, 51, 0.5)),
      url("../../assets/skillsPeople.jpeg") center center no-repeat;
    background-size: cover;
  }

  .lower-section-skills::after {
    display: none !important;
  }

  .lower-section-skills::before {
    display: none !important;
  }

  .info-main-text-skills {
    color: #8d8da6;
    font-size: 14px;
  }
}

/* Styles for screens smaller than 500px */
@media (max-width: 500px) {
  .skill-card {
    width: 100%; /* cards will take full width on very small screens */
  }
}

.why-text {
  font-family: "AauxMedium" !important;
}
